import React from "react"
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import { Link } from "gatsby"
import Contact from "../components/Contact"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Seo } from "../components/seo"
const Process = () => {
  return (


      <section className="container workflow-introduction">
        <div className="row">
          <div className="col-lg-9 col-lg-9">
            <div className="workflow-introduction__flow">
              <SectionTitle>お仕事紹介の流れ</SectionTitle>
              <hr className="u-line-bottom" />
              <div
                className="workflow-introduction__flow--box"
                id="top-workflow"
              >
                <p className="workflow-introduction__flow--box--left">
                  問い合わせ
                </p>
                <div className="workflow-introduction__flow--box--right">
                  <p>
                    下記の申込フォームよりプロフィールをご送付ください。お問い合わせ後3営業日以内にご連絡致します。
                  </p>
                  <div className="btns-flow-box">
                    <Link to="/job-form">無料相談フォーム</Link>
                  </div>
                </div>
              </div>
              <div className="workflow-introduction__flow--box">
                <p className="workflow-introduction__flow--box--left">
                  カウンセリング
                </p>
                <div className="workflow-introduction__flow--box--right">
                  <p>
                    コンサルタントと面談を行います。これまでのご経歴やご希望を伺いながら、キャリアプランニングのお手伝いを致します。必要に応じて勤務地や生活に関する情報もご提供致します。
                  </p>
                  <div className="additional-text">
                    ※面談はメキシコ時間平日AM9時～PM6時にて実施致します。こちらの時間帯が難しい場合は別途ご相談ください。
                    <br />{" "}
                    ※面談の際には事前に日本語版の履歴書・職務経歴書が必要です。必要に応じて
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      to="https://drive.google.com/drive/folders/11gC5PgsfLPbyHesOCgqfgsIKuwmGpAyX"
                    >
                      こちら
                    </Link>
                    よりサンプル、フォーマットをダウンロードください。 <br />
                    面談のツール：Zoom、Google Meet、Skypeをご利用いただけます。
                  </div>
                </div>
              </div>

              <div className="workflow-introduction__flow--box">
                <p className="workflow-introduction__flow--box--left">
                  求人情報のご案内
                </p>
                <div className="workflow-introduction__flow--box--right">
                  <p>
                    これまでのご経験やスキルを基に、ご希望に沿った求人情報をご案内し、ご応募へ向けたサポートを致します。
                  </p>
                </div>
              </div>

              <div className="workflow-introduction__flow--box">
                <p className="workflow-introduction__flow--box--left">
                  求人への応募 <br />
                  （書類選考）
                </p>
                <div className="workflow-introduction__flow--box--right">
                  <p>
                    ご希望の求人が見つかり次第、弊社から企業へ履歴書・職務経歴書をお送りし、書類選考が開始されます。書類選考前には必要に応じて履歴書や職務経歴書の書き方もアドバイス致します。
                  </p>
                </div>
              </div>

              <div className="workflow-introduction__flow--box">
                <p className="workflow-introduction__flow--box--left">
                  選考・面接
                </p>
                <div className="workflow-introduction__flow--box--right">
                  <p>
                    書類選考通過後は、企業との面接を実施します。面接日程の調整等、企業とのやり取りは全て、弊社が行いますので、選考中に直接企業に聞きにくいことがあればいつでもご相談ください。
                  </p>
                </div>
              </div>

              <div className="workflow-introduction__flow--box">
                <p className="workflow-introduction__flow--box--left">
                  内定・入社
                </p>
                <div className="workflow-introduction__flow--box--right">
                  <p>
                    内定承諾後はご入社までのやり取りを企業と直接行っていただきますが、安心してご入社いただくため、弊社も継続してきめ細かくフォローいたします。
                  </p>
                </div>
              </div>

              <div className="workflow-introduction__flow--box none-arrow">
                <p className="workflow-introduction__flow--box--left">入社後</p>
                <div className="workflow-introduction__flow--box--right">
                  <p>
                    就業後のご相談も承っております。お困りの事等がございましたら、クイックグローバルメキシコへお気軽にご相談ください。
                  </p>
                </div>
              </div>

              <button
                onClick={() => scrollTo("#top-workflow")}
                className="btn-register"
              >
                申込フォームから申し込む
              </button>
              <ul className="workflow-introduction__flow--ul">
                <li>※具体的な求人の紹介には登録面談が必要となります。</li>
                <li>
                  ※ご登録は、お仕事の紹介および就業をお約束するものではありません。
                </li>
                <li>
                  ※ご希望やご経験によってはお仕事のご紹介が難しい場合もありますので、ご了承ください。
                </li>
                <li>※弊社がご提供するサービスはすべて無料です。</li>
              </ul>
            </div>
            <Contact lang={"ja"} />
          </div>
          <WrapperRight />
        </div>
      </section>

  )
}

export default Process


export const Head = () => {
  return (
    <Seo
      title="お仕事紹介の流れ"
      description="メキシコの求人情報の掲載、就職・転職サポートを行う転職エージェントサービス。メキシコ国内の転職だけでなく、日本からの転職もサポート。"
      url={`https://www.919mexico.com/process`}
    />
  )
}
